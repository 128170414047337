export const environment = {
  production: true,
  environment: 'prod',
  docio: {
    aadScopeAdmin: ['https://identity.escrowtab.com/docio/access_as_admin'],
    aadScope: ['https://identity.escrowtab.com/docio/access_as_user'],
    domain: 'https://docio.escrowtab.com',
  },
  apiServer: {
    domain: 'https://app.escrowtab.com/api/v1/client',
    aadScope: [
      'https://identity.escrowtab.com/auth/api1',
      'https://identity.escrowtab.com/auth/api2',
    ],
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJldF9kYXRhX2FsbCI6IjEiLCJuYmYiOjE2NDc1MTc3NDEsImV4cCI6NDc3MTY1NTM0MSwiaWF0IjoxNjQ3NTE3NzQxfQ.JrgSiYv9QMtTY1LQSbPzIzUxZNq-c3nN_TlrC82APP4',
  },
  apiDocPrep: {
    domain: 'https://escrowtab-documentprepare.azurewebsites.net/api',
    aadScope: ['https://identity.escrowtab.com/docprep/access_as_user'],
    aadScopeAdmin: ['https://identity.escrowtab.com/docprep/access_as_admin'],
  },
  apiOrders: {
    domain: 'https://orders-api.escrowtab.com/api',
    aadScope: ['https://identity.escrowtab.com/orders/Orders.Access'],
  },
  apiSecureLinks: {
    domain: 'https://links-api.escrowtab.com/api',
    aadScope: ['https://identity.escrowtab.com/links/User.Read'],
  },
  nnaScheduler: {
    domain: 'https://scheduler-stg.escrowtab.com/api/nna-scheduler',
  },
  auditService: {
    domain: 'https://auditv2-api.escrowtab.com/api',
    aadScope: ['https://identity.escrowtab.com/auditv2/access_as_user'],
  },
  adminAPI: {
    domain: 'https://admin-api.escrowtab.com/api',
    aadScope: ['https://identity.escrowtab.com/admin/access_as_user'],
  },
  googleAPIS: 'AIzaSyCr9L8QfLTwHpCsuJgFUyirgYib51zAkk0',
  sentryDns:
    'https://c2bc05c4cb3344d1af74580285d315df@o1214306.ingest.sentry.io/6377800',
  sentryNativeDns:
    'https://20582132274048b98c709d7b1fd3d279@o1214306.ingest.sentry.io/4503899375927296',
  sentryElsiDns:
    'https://824fc15c76452c4c6d39da7066216807@o1214306.ingest.sentry.io/4506583402086400',
  aad: {
    clientId: '6b2def53-b0cd-4f7f-acd3-aace65c6c28b',
    clientIdNative: '378ca7ba-b1cb-419b-a247-069ddca9ddfb',
    verifyClientId: '4867df42-68f1-4fe7-bed9-97a365f8b0d3',
    evaultClientId: '36aa10d3-ff41-4807-a56d-e48ed426814e',
    b2cPolicies: {
      names: {
        signIn: 'B2C_1_signin-01',
        editProfile: 'B2C_1_editprofile-01',
      },
      authorities: {
        signIn: {
          authority:
            'https://login.escrowtab.com/identity.escrowtab.com/B2C_1_signin-01',
        },
        editProfile: {
          authority:
            'https://login.escrowtab.com/identity.escrowtab.com/B2C_1_editprofile-01',
        },
      },
      authorityDomain: ['identity.escrowtab.com', 'login.escrowtab.com'],
    },
  },
  adminAAD: {
    clientId: 'e0e76bf8-9d85-4df6-a890-091ab415f3e9',
    b2cPolicies: {
      authorities: {
        signIn: {
          authority:
            'https://login.microsoftonline.com/36af3843-3860-422b-8cbe-52bc996630e9',
        },
      },
      authorityDomain: ['escrowtab.com'],
    },
  },
  packetService: {
    domain: 'https://packet-api.escrowtab.com',
    aadScope: ['https://identity.escrowtab.com/packet/access_as_user'],
    aadScopeAdmin: ['https://identity.escrowtab.com/packet/access_as_admin'],
  },
  docPrepService: {
    domain: 'https://docprep-api.escrowtab.com',
    aadScope: ['https://identity.escrowtab.com/docprep/access_as_user'],
    aadScopeAdmin: ['https://identity.escrowtab.com/docprep/access_as_admin'],
  },
  profilev2: {
    domain: 'https://profilev2-api.escrowtab.com',
    aadScope: ['https://identity.escrowtab.com/profilev2/access_as_user'],
    aadScopeAdmin: ['https://identity.escrowtab.com/profilev2/access_as_admin'],
  },
  amplitude: 'a0dc2fdfc9ffd20fd872af2817825790',
  stripe: {
    publisherKey:
      'pk_live_51L6K9fJrriO4Nt49REbEG4nfcWd8NJTjyZXspshByBL3h02Rrmf5t6ZHK6bhXKZ33HkKhfDKlJQTEsYNTlOOjcgZ004XvGWjk9',
  },
  pusher: {
    apiKey: '6b3e2fbe5274df819cd9',
    cluster: 'mt1',
    channelAuthorizationEndpoint:
      'https://packet-api.escrowtab.com/api/Signer/pageNavigationAuth',
  },
};
